export default {
    environment: 'production',
    siteRoot: 'https://tipple.com.au',
    authenticationCookie: 'tipple-token',
    existingAuthenticationCookie: 'tpl_session',
    cookieDomain: ".tipple.com.au",
    confirmedAddressCookie: 'tipple-address',
    deviceIdentifierCookie: 'tipple-device-identifier',
    ageVerificationCookie: 'tipple-age-verification',
    userIdentifierCookie: 'tipple-user-identifier',
    imageBase: "https://content.tipple.com.au/tipple",
    authenticationURI: '/api',
    baseURI: '/api',
    googleApiKey: 'AIzaSyAITYPrbzZ45W5_ir5uZ2eHDGtaFfFOEdY',
    splitIoAuthorizationKey: '18bibo2av2guufd2oug1ktal316qi0dar7jg',
    mapboxKey: 'pk.eyJ1IjoidGlwcGxlIiwiYSI6ImNrMG9rMWJ1YTBia2kzbHBrbXA4MnViNjUifQ.CHYrnxxi4oxOiRA7JW10WQ',
    splitIoTrafficType: 'user',
    nrLicenseKey: "5849d0b811",
    nrApplicationID: "236904215",
    nrReleaseName: '@@NR_RELEASE_NAME@@',
    nrReleaseId: '@@NR_RELEASE_ID@@',
    cacheBust: '5e85523679f9035be66561a9ea182e8f105567df',
    segmentKey: "3EABbskPa4teN0756vp93BChdQZIh4zt",
    liveChatLicense: "7330911",
    enableAnalytics: true,
    defaultStorePath: "/bottleshop/victoria/melbourne",
    defaultStoreId: 34,
    siteId: 1,
    idVerificationRedirectURI: 'https://tipple.com.au/verify/identification'
}
